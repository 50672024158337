@charset 'UTF-8';
@import "/Users/huytran/Websites/vhost/morijuken2021/src/scss/utility/_media-queries.scss";
@import "/Users/huytran/Websites/vhost/morijuken2021/src/scss/utility/_mixin.scss";


#page404 {
  .page_404 {padding: 140px 10px; text-align: center; display: block; @include font-size(18); line-height: 30px; letter-spacing: 2px; text-align: center; margin: 20px 0 0 0;
    a { @include font-size(18); text-decoration: none; color: #00B0FF; }
  }
  .cmn-title02 {
    @include font-size(40);
    margin-bottom: 20px;
  }
  @include SP {
    .page_404 {
      @include font-size(16);
      a {
        @include font-size(15);
        @include IP5 {
          @include font-size(13);
        }
      }
    }
  }
}